import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import styles from './styles.module.scss';
import Divider from 'components/atoms/Divider';
import Checkout from 'models/application/Checkout.model';
import { getCurrency } from 'languages/helpers.languages';
import { House } from 'models/application/House.model';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from 'languages';
import { LanguageAcronym } from 'utils/enums/languages.enum';
import moment from 'moment';
/*import BookingContext from 'store/context/booking.context';
import { useContext, useEffect } from 'react';
import useHouse from 'hooks/useHouse';
import useFilters from 'hooks/useFilters';*/

type SummaryOfTheOrderProps = {
  checkOut?: Checkout;
  houseBooking: House;
  dateChecking: string;
  dateCheckout: string;
};

const SummaryOfTheOrder: React.FC<SummaryOfTheOrderProps> = ({
  checkOut,
  houseBooking,
  dateChecking,
  dateCheckout,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialLanguage = i18n.language as LanguageAcronym;
  const [currentLanguage, setCurrentLanguage] = useState<LanguageAcronym>(initialLanguage);
  //const { setHouseBooking, houseBooking } = useContext(BookingContext);

  //const { getHouseId } = useFilters();
  //const { house } = useHouse(getHouseId());

  const checkLargeDescription = houseBooking?.name.length >= 180;

  const currency = getCurrency();

  useEffect(() => {
    if (!houseBooking) {
      navigate(`${window.location.pathname.replace('/checkout/', '/house-details/')}${window.location.search}`);
    }
  }, [houseBooking]);

  useEffect(() => {
    i18n.on('languageChanged', (l) => {
      setCurrentLanguage(l as LanguageAcronym);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, []);

  if (!checkOut) return null;

  const calculateNumberOfDailys = (checkin: string, checkout: string) => {
    const checkinDate = moment(checkin);
    const checkoutDate = moment(checkout);
    return checkoutDate.diff(checkinDate, 'days');
  };

  return (
    <div className={styles.container}>
      <div className={styles.flexColumn}>
        <img src={houseBooking?.pictures[0].url} alt={t('BACKEND.DESCRIPTION')} loading="lazy" />
        <Tooltip title={checkLargeDescription ? houseBooking.name : null}>
          <div className={styles.houseName}>
            {currentLanguage === 'pt-BR' &&
              houseBooking?.namePT.substring(0, 180) + (checkLargeDescription ? '...' : '')}
            {currentLanguage === 'pt' && houseBooking?.namePT.substring(0, 180) + (checkLargeDescription ? '...' : '')}
            {currentLanguage === 'pt-PT' &&
              houseBooking?.namePT.substring(0, 180) + (checkLargeDescription ? '...' : '')}
            {currentLanguage === 'es' && houseBooking?.nameES.substring(0, 180) + (checkLargeDescription ? '...' : '')}
            {currentLanguage === 'en' && houseBooking?.nameUS.substring(0, 180) + (checkLargeDescription ? '...' : '')}
            {currentLanguage === 'en-US' &&
              houseBooking?.nameUS.substring(0, 180) + (checkLargeDescription ? '...' : '')}
          </div>
        </Tooltip>
      </div>
      <Divider color="#DEDEDE" />
      <div className={styles.priceInfo}>{t('CHECKOUT.HOUSE_DETAILS.PRICE_INFO')}</div>
      <div className={styles.dataLine}>
        <div>{t('CARD.DAILYS') + ` (${calculateNumberOfDailys(dateChecking, dateCheckout)})`}</div>
        <div>
          {currency} {checkOut.getDailyTotal()}
        </div>
      </div>
      <div className={styles.dataLine}>
        <div>{t('CARD.SERVICE_FEE')}</div>
        <div>
          {currency} {checkOut.getServiceFee()}
        </div>
      </div>
      <div className={styles.dataLine}>
        <div>{t('CARD.TAXES')}</div>
        <div>
          {currency} {checkOut.getTaxes()}
        </div>
      </div>
      <div className={styles.dataLine}>
        <div>{t('CARD.PLATFORM_FEE')}</div>
        <div>
          {currency} {checkOut.getPlatformFee()}
        </div>
      </div>
      {checkOut.getDiscount() !== '0' && (
        <div className={styles.dataLine}>
          {checkOut?.getIsPartner() === false && <div>{t('CARD.LONG_STAY_DISCOUNT')}</div>}
          {checkOut?.getIsPartner() === true && <div>{t('CARD.PARTNER_DISCOUNT')}</div>}
          <div>
            {currency} {checkOut.getDiscount()}
          </div>
        </div>
      )}
      <Divider color="#DEDEDE" />
      <div className={styles.dataLine}>
        <div className={styles.total}>Total</div>
        <div className={styles.totalValue}>
          {currency} {checkOut.getTotalPrice()}
        </div>
      </div>
    </div>
  );
};

export default SummaryOfTheOrder;
