import Button from 'components/BackOffice/Button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { REPORTS_LIST } from 'routes/routes/backOffice.routes';
import { getUser } from 'services/Login/user.service';

const ButtonReports = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useMemo(() => getUser(), []);
  const isUserGoweasy = user.isGoWeasy();

  const handleReportsNavigation = () => {
    navigate(REPORTS_LIST);
  };
  return <>{isUserGoweasy && <Button onClick={() => handleReportsNavigation()}>{t('BACKOFFICE.REPORTS')} </Button>}</>;
};

export default ButtonReports;
