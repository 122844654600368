import styles from './styles.module.scss';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { IoSearchSharp } from 'react-icons/io5';
import Button from 'components/BackOffice/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import LinkedAgenciesTable from './Components/LinkedAgenciesTable';
import FeatureRow from 'components/BackOffice/FeatureRow';
import { REGISTER_OPERATORS_AND_AGENCIES } from 'routes/routes/backOffice.routes';

const LinkedAgencies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const agencyId = urlParams.agencyId; // id da operadora

  const [search, setSearch] = useState('');

  const handleChange = (event: any) => {
    setSearch(event.target.value);
  };

  const handleAvailabilityNavigation = () => {
    navigate('/admin/check-availability');
  };

  const handleRegisterAgency = () => {
    navigate(REGISTER_OPERATORS_AND_AGENCIES, {
      state: {
        form: 'Agency',
        operatorId: Number(agencyId),
      },
    });
  };

  return (
    <div>
      <div className={styles.info}>
        <FeatureRow title={`#${agencyId} ${t('BACKOFFICE.OPERATOR')}`} />
      </div>
      <div className={styles.content}>
        <div className={styles.actions}>
          <Grid container spacing={2}>
            <Grid item lg={4}>
              <div className={styles.inputs}>
                <TextField
                  label={t('BACKOFFICE.SEARCH')}
                  variant="outlined"
                  value={search}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IoSearchSharp size={24} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item lg={8}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Button onClick={() => handleAvailabilityNavigation()}>{t('BACKOFFICE.AVAILABILITY')} </Button>
                <Button onClick={() => handleRegisterAgency()}> {t('ADMINISTRATIVE.REPORTS.REGISTER_AGENCY')} </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.table}>
          <LinkedAgenciesTable id={agencyId} filter={search} />
        </div>
      </div>
    </div>
  );
};

export default LinkedAgencies;
