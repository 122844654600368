import React, { useContext, useEffect, useMemo } from 'react';

import { useQueryString } from 'hooks/useQueryString';

import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './Form';
import SummaryOfTheOrder from './SummaryOfTheOrder';

import styles from './styles.module.scss';
import Copyright from 'components/Copyright';
import COLORS from 'constants/colors';
import useCheckout from 'hooks/useCheckout.hook';
import { useParams } from 'react-router-dom';
import LoadingSpinner from 'components/atoms/loading';
import BookingContext from 'store/context/booking.context';
import useFilters from 'hooks/useFilters';

export type CheckoutPageParams = Pick<
  {
    checkIn: string;
    checkOut: string;
    houseId: number;
  },
  'checkIn' | 'checkOut' | 'houseId'
> & {
  guest?: { adults: number; kids: number; babies: number; pets: number; pcd: number; elderly: number; total: number };
  paymentIntentId?: string;
};

const CheckoutPage: React.FC = () => {
  const [qsData] = useQueryString<Pick<CheckoutPageParams, 'checkIn' | 'checkOut' | 'guest'>>();
  const { houseBooking } = useContext(BookingContext);
  const { getFiltersAsQueryString, getTotalGuests } = useFilters();

  const { houseId } = useParams<{ houseId: string }>();

  const { loadingCheckout, stripeConfig, houseCheckout, getCheckoutData, configError, isUsingStripe } = useCheckout();

  const redirectURL = useMemo(() => {
    const query = getFiltersAsQueryString();
    return `/house-details/${houseId}?${query}`;
  }, [qsData, houseId]);

  useEffect(() => {
    const guests = getTotalGuests();
    if (guests > 0) {
      getCheckoutData(Number(houseId), qsData.checkIn, qsData.checkOut, guests);
    }
  }, [houseId, qsData.checkIn, qsData.checkOut]);

  return (
    <div className={styles.content}>
      {!houseBooking || configError ? <div>Erro ao carregar configuração stripe</div> : null}
      {loadingCheckout || !stripeConfig || !houseCheckout ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.mainPage}>
              {isUsingStripe ? (
                <Elements options={stripeConfig.getOptions()} stripe={stripeConfig.getStripePromise()}>
                  <CheckoutForm
                    checkIn={qsData.checkIn}
                    checkOut={qsData.checkOut}
                    costTotal={houseCheckout.getTotalPriceNumber()}
                    paymentIntent={stripeConfig.paymentIntentId}
                    goBackURL={redirectURL}
                    house={houseBooking}
                    isUsingStripe={isUsingStripe}
                    installments={houseCheckout.installments}
                  />
                  <SummaryOfTheOrder
                    checkOut={houseCheckout}
                    houseBooking={houseBooking}
                    dateChecking={qsData.checkIn}
                    dateCheckout={qsData.checkOut}
                  />
                </Elements>
              ) : (
                <>
                  <CheckoutForm
                    checkIn={qsData.checkIn}
                    checkOut={qsData.checkOut}
                    costTotal={houseCheckout.getTotalPriceNumber()}
                    paymentIntent={stripeConfig.paymentIntentId}
                    goBackURL={redirectURL}
                    house={houseBooking}
                    isUsingStripe={isUsingStripe}
                    installments={houseCheckout.installments}
                  />
                  <SummaryOfTheOrder
                    checkOut={houseCheckout}
                    houseBooking={houseBooking}
                    dateChecking={qsData.checkIn}
                    dateCheckout={qsData.checkOut}
                  />
                </>
              )}
            </div>
          </div>
          <Copyright color={COLORS.neutral.white} backgroundColor={COLORS.neutral.black} />
        </>
      )}
    </div>
  );
};

export default CheckoutPage;
