import FeatureRow from 'components/BackOffice/FeatureRow';
import styles from './styles.module.scss';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import { IoSearchSharp } from 'react-icons/io5';
import AgentTable from 'components/BackOffice/AgentTable';
import Button from 'components/BackOffice/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useBackOfficeNavigation from 'routes/navigation/backOffice.navigation';
import { getUser } from 'services/Login/user.service';
import { REPORTS_LIST } from 'routes/routes/backOffice.routes';
// import Inputs from 'components/molecules/inputs';

const Management = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useMemo(() => getUser(), []);
  const isUserGoweasy = user.isGoWeasy();

  const { navigateToAgent } = useBackOfficeNavigation();

  const [search, setSearch] = useState('');
  // const [orderBy, setOrderBy] = useState();

  const handleChange = (event: any) => {
    setSearch(event.target.value);
  };

  const handleReportsNavigation = () => {
    navigate(REPORTS_LIST);
  };

  const handleReservationNavigation = () => {
    navigate('/admin/reservations');
  };

  const handleCommissionsNavigation = () => {
    navigate('/admin/commissions');
  };

  const handleAvailabilityNavigation = () => {
    navigate('/admin/check-availability');
  };

  // const handleValue = (valueParam: any) => {
  //   setOrderBy(valueParam);
  // };

  return (
    <div>
      <div className={styles.info}>
        <FeatureRow title={t('BACKOFFICE.MANAGEMENT')}>
          <div></div>
        </FeatureRow>
      </div>
      <div className={styles.content}>
        <div className={styles.actions}>
          <Grid container spacing={2}>
            <Grid item lg={4}>
              <div className={styles.inputs}>
                <TextField
                  label={t('BACKOFFICE.SEARCH')}
                  variant="outlined"
                  value={search}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IoSearchSharp size={24} />
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <Inputs.Select
              label="Ordenar por"
              autoComplete="new-password"
              value={orderBy}
              onChangeValue={handleValue}
              options={[{ value: 'NOME', label: 'Nome' }]}
            /> */}
              </div>
            </Grid>
            <Grid item lg={8}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {isUserGoweasy && <Button onClick={() => handleReportsNavigation()}>{t('BACKOFFICE.REPORTS')} </Button>}
                <Button onClick={() => handleReservationNavigation()}>{t('BACKOFFICE.RESERVATIONS')} </Button>
                <Button onClick={() => handleCommissionsNavigation()}>{t('BACKOFFICE.COMMISSIONS')} </Button>
                <Button onClick={() => handleAvailabilityNavigation()}>{t('BACKOFFICE.AVAILABILITY')} </Button>
                <Button onClick={navigateToAgent}>{t('BACKOFFICE.REGISTER')} </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.table}>
          <AgentTable filter={search} />
        </div>
      </div>
    </div>
  );
};

export default Management;
