import { Grid, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { IoSearchSharp } from 'react-icons/io5';
import Button from 'components/BackOffice/Button';
import useBackOfficeNavigation from 'routes/navigation/backOffice.navigation';
import { useNavigate, useParams } from 'react-router-dom';
import LinkedAgentsTable from './Components/LinkedAgentsTable';
import HeaderReservationAgents from './Components/HeaderReservationAgents';

const LinkedAgents = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const agencyId = urlParams.id;

  const { navigateToAgent } = useBackOfficeNavigation();

  const [search, setSearch] = useState('');
  // const [orderBy, setOrderBy] = useState();

  const handleChange = (event: any) => {
    setSearch(event.target.value);
  };

  const handleReservationNavigation = () => {
    navigate('/admin/reservations', { state: { id: agencyId } });
  };

  const handleCommissionsNavigation = () => {
    navigate('/admin/commissions', { state: { agencyId: agencyId } });
  };

  const handleAvailabilityNavigation = () => {
    navigate('/admin/check-availability');
  };

  // const handleValue = (valueParam: any) => {
  //   setOrderBy(valueParam);
  // };

  return (
    <div>
      <div className={styles.info}>
        <HeaderReservationAgents title={`#${agencyId} ${t('BACKOFFICE.TABLE.USER')}`} />
      </div>
      <div className={styles.content}>
        <div className={styles.actions}>
          <Grid container spacing={2}>
            <Grid item lg={6}>
              <div className={styles.inputs}>
                <TextField
                  label={t('BACKOFFICE.SEARCH')}
                  variant="outlined"
                  value={search}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IoSearchSharp size={24} />
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <Inputs.Select
              label="Ordenar por"
              autoComplete="new-password"
              value={orderBy}
              onChangeValue={handleValue}
              options={[{ value: 'NOME', label: 'Nome' }]}
            /> */}
              </div>
            </Grid>
            <Grid item lg={6}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Button onClick={() => handleReservationNavigation()}>{t('BACKOFFICE.RESERVATIONS')} </Button>
                <Button onClick={() => handleCommissionsNavigation()}>{t('BACKOFFICE.COMMISSIONS')} </Button>
                <Button onClick={() => handleAvailabilityNavigation()}>{t('BACKOFFICE.AVAILABILITY')} </Button>
                <Button onClick={navigateToAgent}>{t('BACKOFFICE.REGISTER')} </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.table}>
          <LinkedAgentsTable filter={search} />
        </div>
      </div>
    </div>
  );
};

export default LinkedAgents;
