//import { DoneDeal, HouseGreen, Revoked } from 'assets';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import useAsync from 'hooks/useAsync.hook';
//import StatsSection from 'components/StatsSection';
import FeatureRow from 'components/BackOffice/FeatureRow';
import { IoSearchSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import Button from 'components/BackOffice/Button';
import moment from 'moment';
import styles from './styles.module.scss';
import RangeDatePicker from 'components/BackOffice/RangeDatePicker';
import ReportsTable, { Language } from './ReportsTable';
import getReportsTableList from 'services/Reports/getReportsTableList';
import i18n from 'languages';
import getGoalSettings from 'services/Reports/getGoalSetting';
import getGoalDashboard from 'services/Reports/getGoalDashboard';
import GoalSettingModal from './GoalSettingModal';
import { registerGoal } from 'services/Reports/registerGoal';
import { toast } from 'react-toastify';
import { CircleGreen, CircleLightPink, CircleLightRed, CircleOrange } from 'assets';
import StatsSection from 'components/StatsSection';
import { getUser } from 'services/Login/user.service';
import { Navigate } from 'react-router-dom';
//import getGoalSettings from 'services/Reports/getGoalSetting';
//import getGoalDashboard from 'services/Reports/getGoalDashboard';

// TODO: Passar o componente pra dentro da pasta backoffice

const monthOrder = [
  'JANEIRO',
  'FEVEREIRO',
  'MARÇO',
  'ABRIL',
  'MAIO',
  'JUNHO',
  'JULHO',
  'AGOSTO',
  'SETEMBRO',
  'OUTUBRO',
  'NOVEMBRO',
  'DEZEMBRO',
];

const ReportsList = () => {
  const user = useMemo(() => getUser(), []);
  const today = moment().format('YYYY-MM-DD HH:mm:ss');
  const [period, setPeriod] = useState(['01-01-2024', '12-12-2024']);
  const startDate = moment(period?.[0]).format('YYYY-MM-DD');
  const endDate = moment(period?.[1]).format('YYYY-MM-DD');
  const { t } = useTranslation();
  const [csvData, setCsvData] = useState<any>([]);
  const [code, setCode] = useState<any>('');
  const [reportsDataTable, setReportsDataTable] = useState<any>([]);
  const initialLanguage = i18n.language as Language;
  const [currentLanguage, setCurrentLanguage] = useState<Language>(initialLanguage);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [goalsData, setGoalsData] = useState<any>([]);
  const [dashBoardData, setDashboardData] = useState<any>({});
  const [selectedGoal, setSelectedGoal] = useState<any>([]);
  const [goalValue, setGoalValue] = useState<any>();
  const validateGoweasy = () => {
    if (user.isGoWeasy()) return true;
    toast.error(t('ADMINISTRATIVE.FORBIDDEN'));

    return false;
  };
  //const [loading, setLoading] = useState<any>(false);

  const reportsDataTableFiltered = reportsDataTable?.filter((r: any) => String(r.id).toLowerCase().includes(code));
  const dashboardStatsNumbers = {
    monthly: dashBoardData?.valorMes,
    daily: dashBoardData?.valorDiaria,
    cancel: dashBoardData?.reservasCanceladas,
    reached: dashBoardData?.porcentagemMes,
  };

  const headers = [
    { label: t('ADMINISTRATIVE.REPORTS.RESERVATIONS'), key: 'id' },
    { label: t('ADMINISTRATIVE.REPORTS.PROPERTY'), key: 'imovel' },
    { label: t('ADMINISTRATIVE.REPORTS.PM'), key: 'pmName' },
    { label: t('ADMINISTRATIVE.REPORTS.DATE'), key: 'date' },
    { label: t('ADMINISTRATIVE.REPORTS.OPERATOR'), key: 'operador' },
    { label: t('ADMINISTRATIVE.REPORTS.AGENCY'), key: 'agencia' },
    { label: t('ADMINISTRATIVE.REPORTS.AGENT'), key: 'agente' },
    { label: t('ADMINISTRATIVE.REPORTS.SALE'), key: 'venda' },
    { label: t('ADMINISTRATIVE.REPORTS.DOLLAR_EXCHANGE_RATE'), key: 'cotacaoDolar' },
    { label: t('ADMINISTRATIVE.REPORTS.DAILY'), key: 'diarias' },
    { label: t('ADMINISTRATIVE.REPORTS.CLEANING'), key: 'limpeza' },
    { label: t('ADMINISTRATIVE.REPORTS.COMMISSION_LP'), key: 'limpezaComissao' },
    { label: t('ADMINISTRATIVE.REPORTS.COMMISSION_AG'), key: 'comissaoAgencia' },
    { label: t('ADMINISTRATIVE.REPORTS.COMMISSION_GW'), key: 'comissaoGoweasy' },
    { label: t('ADMINISTRATIVE.REPORTS.PAGTOUR_FEE'), key: 'taxaPagtour' },
    { label: t('ADMINISTRATIVE.REPORTS.TAXES'), key: 'impostos' },
    { label: t('ADMINISTRATIVE.REPORTS.DISCOUNTS'), key: 'descontos' },
    { label: t('ADMINISTRATIVE.REPORTS.GW_FEE'), key: 'taxaGoWeasyPlataforma' },
    { label: t('ADMINISTRATIVE.REPORTS.TOTAL_GW'), key: 'ganhosTotalGoWeasy' },
    { label: '%', key: 'porcentagem' },
    { label: 'STATUS', key: 'status' },
  ];
  // TODO: change statsTest
  const statsDashboard = [
    {
      name: t('ADMINISTRATIVE.DASHBOARD.TITLE_MONTH'),
      status: t('ADMINISTRATIVE.DASHBOARD.SUBTITLE_MONTH'),
      reservationNumbers: '$' + Number(dashboardStatsNumbers.monthly).toFixed(3).slice(0, -1),
      icon: CircleLightRed,
    },
    {
      name: t('ADMINISTRATIVE.DASHBOARD.TITLE_DAILY'),
      status: t('ADMINISTRATIVE.DASHBOARD.SUBTITLE_DAILY'),
      reservationNumbers: '$' + Number(dashboardStatsNumbers.daily).toFixed(3).slice(0, -1),
      icon: CircleOrange,
    },
    {
      name: t('ADMINISTRATIVE.DASHBOARD.TITLE_CANCEL'),
      status: t('ADMINISTRATIVE.DASHBOARD.SUBTITLE_CANCEL'),
      reservationNumbers: dashboardStatsNumbers.cancel,
      icon: CircleLightPink,
    },
    {
      name: t('ADMINISTRATIVE.DASHBOARD.TITLE_PROFIT'),
      status: t('ADMINISTRATIVE.DASHBOARD.SUBTITLE_PROFIT'),
      reservationNumbers: Number(dashboardStatsNumbers.reached).toFixed(3).slice(0, -1) + '%',
      icon: CircleGreen,
    },
  ];
  const handleCodeChange = (event: any) => {
    setCode(event.target.value);
  };

  const handlePeriod = (date: any) => {
    setPeriod(date);
  };

  const getReportsData = (responseData: any) => {
    const newData = responseData?.map((item: any) => ({
      id: item.id,
      imovel: item.imovel,
      pmName: item?.pmName,
      date: formatDate(item?.date, currentLanguage),
      operador: item.operador,
      agencia: item.agencia,
      agente: item.agente,
      venda: Number(item.venda).toFixed(3).slice(0, -1),
      cotacaoDolar: item?.cotacaoDolar,
      diarias: item.diarias,
      limpeza: Number(item.limpeza).toFixed(3).slice(0, -1),
      limpezaComissao: Number(item.limpezaComissao).toFixed(3).slice(0, -1),
      comissaoAgencia: Number(item.comissaoAgencia).toFixed(3).slice(0, -1),
      comissaoGoweasy: Number(item.comissaoGoweasy).toFixed(3).slice(0, -1),
      taxaPagtour: Number(item?.taxaPagtour).toFixed(3).slice(0, -1),
      impostos: Number(item.impostos).toFixed(3).slice(0, -1),
      descontos: item.descontos + '%',
      taxaGoWeasyPlataforma: Number(item.taxaGoWeasyPlataforma).toFixed(3).slice(0, -1),
      ganhosTotalGoWeasy: Number(item.ganhosTotalGoWeasy).toFixed(3).slice(0, -1),
      porcentagem: Number(item.porcentagem).toFixed(3).slice(0, -1),
      status: item.status,
    }));
    return newData;
  };

  const { call: getReports } = useAsync(async (sDate: any, eDate: any) => {
    const response = await getReportsTableList(sDate, eDate);
    setReportsDataTable(response);
    const csvReports = getReportsData(response);
    setCsvData(csvReports);
  }, []);

  const { call: getGoals } = useAsync(async () => {
    const response = await getGoalSettings();
    const goalsOrderedByMonth = response.sort((a: any, b: any) => {
      const indexA = monthOrder.indexOf(a.mes);
      const indexB = monthOrder.indexOf(b.mes);
      if (indexA === -1 || indexB === -1) {
        return 0;
      }
      return indexA - indexB;
    });
    goalsOrderedByMonth.shift();
    setGoalsData(goalsOrderedByMonth);
  }, []);

  const { call: getDashboardInfo } = useAsync(async () => {
    const response = await getGoalDashboard();
    setDashboardData(response);
  }, []);

  const { call: createGoal } = useAsync(async (newGoal: any) => {
    try {
      await registerGoal(newGoal);
      toast.success(t('ADMINISTRATIVE.REPORTS.GOAL_SUCCESS'));
      getGoals();
    } catch {
      toast.error('error');
    }
  }, []);

  const formatDate = (dateString: string, language: Language): string => {
    if (language === 'pt-BR' || language === 'pt-PT') {
      moment.locale('pt-br');
      return moment(dateString).format('DD MMM YYYY HH:mm');
    } else {
      moment.locale('en');
      return moment(dateString).format('MMM DD, YYYY HH:mm');
    }
  };

  const handleModal = () => {
    setIsModalOpen(true);
  };

  const onCreateGoal = () => {
    if (selectedGoal?.length > 1) {
      const updatedGoal = goalsData.find((v: any) => v?.mes == selectedGoal);
      const newGoal = [
        {
          id: updatedGoal.id,
          meta: updatedGoal.mes,
          valor: goalValue,
        },
      ];
      createGoal(newGoal);
    } else {
      toast.error(t('ADMINISTRATIVE.REPORTS.SELECT_MOTH'));
    }
  };

  const handleSelect = (month: any) => {
    setSelectedGoal(month);
  };

  const handleValue = (value: any) => {
    setGoalValue(value);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    return 0;
  };

  const handleLogout = () => {
    return <Navigate to="/" replace />;
  };

  useEffect(() => {
    getReports(startDate, endDate);
    getGoals();
    getDashboardInfo();
  }, [period]);

  useEffect(() => {
    // TODO: tentar melhorar isso com um hook talvez
    i18n.on('languageChanged', (l) => {
      setCurrentLanguage(l as Language);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, []);

  return (
    <>
      {validateGoweasy() ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FeatureRow title={t('ADMINISTRATIVE.REPORTS.FINANCIAL_REPORT')} noJustify>
            <div style={{ display: 'flex' }}>
              {statsDashboard.map((stat) => {
                return (
                  <StatsSection
                    name={stat.name}
                    status={stat.status}
                    reservationNumbers={stat.reservationNumbers}
                    icon={stat.icon}
                  />
                );
              })}
            </div>
          </FeatureRow>
          <Grid container spacing={1} style={{ alignItems: 'center' }}>
            <Grid item lg={8}>
              <div style={{ display: 'flex', gap: '20px', flexDirection: 'row', alignItems: 'center' }}>
                <TextField
                  label={t('BACKOFFICE.RESERVATIONSCREEN.SEARCH_BY_RESERVATION_ID')}
                  variant="outlined"
                  value={code}
                  onChange={handleCodeChange}
                  style={{ width: '80%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IoSearchSharp size={24} />
                      </InputAdornment>
                    ),
                  }}
                />
                <RangeDatePicker
                  cleanOff
                  minDate={new Date('01-01-2020')}
                  value={period}
                  onChangeValue={handlePeriod}
                />
              </div>
            </Grid>
            <Grid item lg={4}>
              <div style={{ display: 'flex', gap: '20px', flexDirection: 'row', alignItems: 'center' }}>
                <Button className={styles.buttonReports} onClick={handleModal}>
                  Cadastrar Meta
                </Button>
                <Button className={styles.buttonGenerateReports}>
                  <CSVLink
                    className={styles.buttonDownload}
                    data={csvData}
                    headers={headers}
                    filename={`Financial Reports - ${today}.csv`}
                  >
                    {t('BACKOFFICE.RESERVATIONSCREEN.DOWNLOAD')}
                  </CSVLink>
                </Button>
              </div>
            </Grid>
          </Grid>
          <ReportsTable data={reportsDataTableFiltered} />
          <GoalSettingModal
            isOpen={isModalOpen}
            data={goalsData}
            onConfirm={onCreateGoal}
            onSelect={handleSelect}
            onChangeValue={handleValue}
            selectedValue={selectedGoal}
            goalValue={goalValue}
            onClose={onCloseModal}
          />
        </div>
      ) : (
        handleLogout()
      )}
    </>
  );
};

export default ReportsList;
